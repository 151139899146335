/* eslint-disable no-unused-vars */
import Chart from "components/utils/Chart";
import SelectInput, {
  Option,
} from "components/utils/SelectInput";

import PropTypes from "prop-types";
import styles from "./DashboardTrends.module.scss";
import * as _dashboardActions from "store/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { trends } from "store/dashboard/selectors";
import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import SectionLoader from "components/utils/SectionLoader";
import { allDivisions } from "store/system/selectors";
import { authUser } from "store/user/selectors";

const DashboardTrends = props => {
  const initialized = useRef(false);

  const { variant, className } = props;

  const dispatch = useDispatch();

  const dashboardActions = useMemo(
    () => bindActionCreators(_dashboardActions, dispatch),
    [dispatch]
  );

  const loggedInUser = useSelector(authUser);

  const _trends = useSelector(trends);

  const [divisionValue, setDivisionValue] = useState("");

  const divisions = useSelector(allDivisions);

  const [loading, setLoading] = useState(false);

  const [selections, setSelections] = useState({
    division: "",
    date: "all_time",
  });

  const handleSelection = e => {
    setSelections({
      ...selections,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "division") {
      setDivisionValue(e.target.value);
    }
  };

  const fetchTrendsData = async () => {
    setLoading(true);
    const options = {
      params: {
        date: selections.date,
        divisions_id: selections.division,
      },
    };
    const res = await dashboardActions
      .getTrendsData({ options })
      .unwrap();
    if (res.success) {
      // console.log(res);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTrendsData();
  }, [selections.division, selections.date]);

  useEffect(async () => {
    const { role, divisions_id } = loggedInUser;
    if (!["admin"].includes(role.slug)) {
      setDivisionValue(divisions_id);
      setSelections({
        ...selections,
        division: divisions_id,
      });
    }
    initialized.current = true;
  }, []);

  return (
    <div
      className={`${styles.DashboardTrends} ${
        styles[`DashboardTrends__${variant} trends`]
      } ${className}`}
    >
      <div className="trends__header">
        <h2 className="lg">Trends</h2>

        <div className="trends__sort">
          {["admin"].includes(loggedInUser.role.slug) && (
            <SelectInput
              name="division"
              onChange={handleSelection}
              variant="standard"
              value={divisionValue}
            >
              <Option value="0">All</Option>
              {divisions.map(division => (
                <Option
                  key={division.id}
                  value={division.id}
                >
                  {division.name}
                </Option>
              ))}
            </SelectInput>
          )}

          <SelectInput
            name="date"
            onChange={handleSelection}
            variant="standard"
          >
            <Option value="all_time">All time</Option>
            <Option value="past_7days">Past 7 days</Option>
            <Option value="past_30days">
              Past 30 days
            </Option>
            <Option value="past_90days">
              Past 90 days
            </Option>
            <Option value="past_12months">
              Past 12 months
            </Option>
          </SelectInput>
        </div>
      </div>

      {loading ? (
        <SectionLoader />
      ) : (
        <div>
          <div className="trends__row">
            <div className="trends__data">
              <div className="estimators trends__data-col trends__data-col--blue">
                <h3>Number of Estimates</h3>
                <p>{_trends?.no_of_estimates}</p>
              </div>
              <div className="crews trends__data-col trends__data-col--green">
                <h3>Number of Jobs</h3>
                <p>{_trends?.no_of_jobs}</p>
              </div>
            </div>

            {/******JOB ESTIMATE CHART ******/}
            <div className="trends__chart">
              <Chart
                data={
                  selections.date === "past_7days"
                    ? _trends?.weekly_sales
                    : _trends?.graph
                }
                xAxisScale={
                  selections.date === "past_7days"
                    ? "day"
                    : "month"
                }
                dataKey1="jobs"
                dataKey2="estimates"
              />
            </div>
          </div>

          <div className="trends__row">
            <div className="trends__data">
              <div className="trends__data-col trends__data-col--green">
                <h3>Revenue</h3>
                <p>${_trends?.revenue}</p>
              </div>
            </div>

            {/****** REVENUE CHART ******/}
            <div className="trends__chart">
              <Chart
                data={
                  selections.date === "past_7days"
                    ? _trends?.weekly_revenue
                    : _trends?.mothly_revenue
                }
                xAxisScale={
                  selections.date === "past_7days"
                    ? "day"
                    : "month"
                }
                dataKey1="revenue"
                dateType={selections.date}
                revenueMax={_trends?.revenue}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

DashboardTrends.defaultProps = {
  variant: "default",
  className: "",
};

DashboardTrends.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default DashboardTrends;
