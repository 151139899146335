import { createAsyncThunk } from "@reduxjs/toolkit";
import * as userAPI from "api/user";

export const profile = createAsyncThunk(
  "user/profile",
  async data => {
    const res = await userAPI.profile(data);
    return res;
  }
);

export const create = createAsyncThunk(
  "user/create",
  async data => {
    const res = await userAPI.create(data);
    return res;
  }
);

export const get = createAsyncThunk(
  "user/get",
  async data => {
    const res = await userAPI.get(data);
    return res;
  }
);

export const update = createAsyncThunk(
  "user/update",
  async data => {
    const res = await userAPI.update(data);
    return res;
  }
);

export const list = createAsyncThunk(
  "user/list",
  async data => {
    const res = await userAPI.list(data);
    return res;
  }
);

export const remove = createAsyncThunk(
  "user/remove",
  async data => {
    const res = await userAPI.remove(data);
    return res;
  }
);

export const notifications = createAsyncThunk(
  "user/notifications",
  async data => {
    const res = await userAPI.notifications(data);
    return res;
  }
);

export const notificationsCount = createAsyncThunk(
  "user/notificationsCount",
  async data => {
    const res = await userAPI.notificationsCount(data);
    return res;
  }
);

export const markAllRead = createAsyncThunk(
  "user/notificationMarkAllRead",
  async data => {
    const res = await userAPI.markAllRead(data);
    return res;
  }
);

export const markAsRead = createAsyncThunk(
  "user/notificationMarkRead",
  async data => {
    const res = await userAPI.markAsRead(data);
    return res;
  }
);

export const markAsUnRead = createAsyncThunk(
  "user/notificationMarkUnread",
  async data => {
    const res = await userAPI.markAsUnRead(data);
    return res;
  }
);

export const getCrew = createAsyncThunk(
  "user/getCrew",
  async data => {
    const res = await userAPI.getCrew(data);
    return res;
  }
);
