/* eslint-disable no-unused-vars */
import SalesCard from "components/dashboard/SalesCard";
import SelectInput, {
  Option,
} from "components/utils/SelectInput";
import PropTypes from "prop-types";
import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./DashboardSales.module.scss";
//redux
import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import * as _dashboardActions from "store/dashboard/actions";
import { sales } from "store/dashboard/selectors";
import SectionLoader from "components/utils/SectionLoader";
import { allDivisions } from "store/system/selectors";
import { authUser } from "store/user/selectors";

const DashboardSales = props => {
  const initialized = useRef(false);

  const dispatch = useDispatch();

  const dashboardActions = useMemo(
    () => bindActionCreators(_dashboardActions, dispatch),
    [dispatch]
  );

  const _sales = useSelector(sales);

  const [divisionValue, setDivisionValue] = useState("");

  const divisions = useSelector(allDivisions);

  const { variant, className } = props;

  const [loading, setLoading] = useState(false);

  const [selections, setSelections] = useState({
    division: "",
    date: "",
  });

  const loggedInUser = useSelector(authUser);

  const handleSelection = e => {
    setSelections({
      ...selections,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "division") {
      setDivisionValue(e.target.value);
    }
  };

  const fetchSales = async () => {
    setLoading(true);
    const options = {
      params: {
        date: selections.date,
        divisions_id: selections.division,
      },
    };
    const res = await dashboardActions
      .getSales({ options })
      .unwrap();
    if (res.success) {
      // console.log(res);
    }
    setLoading(false);
  };

  useEffect(async () => {
    await fetchSales();
  }, [selections.division, selections.date]);

  useEffect(async () => {
    const { role, divisions_id } = loggedInUser;
    if (!["admin"].includes(role.slug)) {
      setDivisionValue(divisions_id);
      setSelections({
        ...selections,
        division: divisions_id,
      });
    }
    initialized.current = true;
  }, []);

  return (
    <div
      className={`${styles.DashboardSales} ${
        styles[`DashboardSales__${variant} sales`]
      } ${className}`}
    >
      <div className="sales__header">
        <h2 className="lg">Sales</h2>
        <div className="sales__sort">
          {["admin"].includes(loggedInUser.role.slug) && (
            <SelectInput
              name="division"
              id="pet-select"
              onChange={handleSelection}
              variant="standard"
              value={divisionValue}
            >
              <Option value="0">All</Option>
              {divisions.map(division => (
                <Option
                  key={division.id}
                  value={division.id}
                >
                  {division.name}
                </Option>
              ))}
            </SelectInput>
          )}

          <SelectInput
            name="date"
            id="pet-select"
            onChange={handleSelection}
            variant="standard"
          >
            <Option value="all_time">All Time</Option>
            <Option value="last_week">Last Week</Option>
            <Option value="this_week">This Week</Option>
            <Option value="next_week">Next Week</Option>
            <Option value="last_month">Last Month</Option>
            <Option value="this_month">This Month</Option>
            <Option value="next_month">Next Month</Option>
            <Option value="last_year">Last Year</Option>
            <Option value="year_to_date">
              Year to Date
            </Option>
          </SelectInput>
        </div>
      </div>

      {loading ? (
        <SectionLoader />
      ) : (
        <div className="sales__content">
          <div className="sales__card">
            <SalesCard
              name="Number of Estimates"
              count={_sales?.no_of_estimates}
              percent={_sales?.pecentage_difference}
              date={selections}
            />
          </div>
          <div className="sales__card">
            <SalesCard
              name="Number of Jobs"
              count={_sales?.no_of_jobs}
              percent={_sales?.pecentage_difference}
              date={selections}
            />
          </div>
          <div className="sales__card">
            <SalesCard
              name="Revenue"
              count={_sales?.revenue}
              percent={_sales?.pecentage_difference}
              date={selections}
            />
          </div>
        </div>
      )}
    </div>
  );
};

DashboardSales.defaultProps = {
  variant: "default",
  className: "",
};

DashboardSales.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default DashboardSales;
