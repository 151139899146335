import PropTypes from "prop-types";
import styles from "./PreNavbar.module.scss";
import { ReactComponent as Logo } from "assets/icons/arbor-manage-logo-white.svg";
import Button from "components/utils/Button/Button";
import JobSearch from "components/jobs/Search";
import { Link } from "react-router-dom";
import { ReactComponent as Magnifier } from "assets/icons/magnifier-white.svg";
import { ReactComponent as Close } from "assets/icons/close.svg";
import SearchInput from "components/utils/SearchInput";
import NavNotifications from "components/utils/NavNotifications";
import { useSelector } from "react-redux";
import { authUser } from "store/user/selectors";

const PreNavbar = props => {
  const { minimal } = props;

  const user = useSelector(authUser);

  return (
    <nav
      className={`${styles.PreNavbar} ${props.className} navbar`}
    >
      <div className="container-fluid">
        <div className="navbar__wrap">
          {!minimal && (
            <>
              <div className="left-col">
                <NavNotifications />
                {[
                  "admin",
                  "scheduling-admin",
                  "estimator",
                ].includes(user.role?.slug) && (
                  <a href="/new-job" target="_blank">
                    <Button className="btn">New Job</Button>
                  </a>
                )}
              </div>
              <div className="center-col">
                <JobSearch />
              </div>
            </>
          )}
          <div
            className={`right-col nvam-logo-white ${
              minimal ? "ms-auto navbar--minimal" : ""
            }`}
          >
            <Link to={"/"}>
              <Logo />
            </Link>
          </div>
        </div>
        <div className="navbar-mob">
          <div className="navbar-mob__left">
            <div className="burger-menu">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="nvam-logo-white">
              <Link to={"/"}>
                <Logo />
              </Link>
            </div>
          </div>
          <div className="navbar-mob__right">
            <div className="search-icon">
              <Magnifier />
            </div>
            <Button className="btn">New Job</Button>
          </div>
        </div>
        {/* Add .search-show and .search-hide to show and hide the search bar expansion  */}
        <div className="navbar-mob__search search-hide">
          <div className="search-bar">
            <div className="search-input">
              <SearchInput placeholder="Search" />
              <Close className="close-btn" />
            </div>
            <span>Cancel</span>
          </div>
        </div>
      </div>
    </nav>
  );
};

PreNavbar.defaultProps = {
  className: "",
};

PreNavbar.propTypes = {
  className: PropTypes.string,
  minimal: PropTypes.bool,
};

export default PreNavbar;
