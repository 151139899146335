import api from "utils/api";

export const create = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/user",
    method: "POST",
    data,
    ...options,
  });
};

export const get = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/user/:hash",
    method: "GET",
    data,
    ...options,
  });
};

/**
 *
 * @param {*} config
 * Fetches the logged in user details
 */
export const profile = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/user",
    method: "GET",
    data,
    ...options,
  });
};

export const update = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/user/:hash",
    method: "PATCH",
    data,
    ...options,
  });
};

export const list = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/users",
    method: "GET",
    data,
    ...options,
  });
};

export const remove = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/user/:hash",
    method: "DELETE",
    data,
    ...options,
  });
};

export const notifications = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/notifications",
    method: "GET",
    data,
    ...options,
  });
};

export const notificationsCount = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/notifications/count",
    method: "GET",
    data,
    ...options,
  });
};

export const markAllRead = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/notifications/mark-all-as-read",
    method: "POST",
    data,
    ...options,
  });
};

export const authorizeSocket = (config = {}) => {
  const { data, options } = config;
  return api({
    baseURL: process.env.REACT_APP_API_HOST,
    url: "/broadcasting/auth",
    method: "POST",
    data,
    ...options,
  });
};

export const markAsRead = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "v1/notification/:id/mark-as-read",
    method: "POST",
    data,
    ...options,
  });
};

export const markAsUnRead = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "v1/notification/:id/mark-as-unread",
    method: "POST",
    data,
    ...options,
  });
};

export const getCrew = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "v1/user/roles/crew-lead",
    method: "GET",
    data,
    ...options,
  });
};
