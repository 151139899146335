import { createAsyncThunk } from "@reduxjs/toolkit";
import * as propertyAPI from "api/property";

export const create = createAsyncThunk(
  "property/create",
  async data => {
    const res = await propertyAPI.create(data);
    return res;
  }
);

export const get = createAsyncThunk(
  "property/get",
  async data => {
    const res = await propertyAPI.get(data);
    return res;
  }
);

export const update = createAsyncThunk(
  "property/update",
  async data => {
    const res = await propertyAPI.update(data);
    return res;
  }
);

export const list = createAsyncThunk(
  "property/list",
  async data => {
    const res = await propertyAPI.list(data);
    return res;
  }
);

export const remove = createAsyncThunk(
  "property/remove",
  async data => {
    const res = await propertyAPI.remove(data);
    return res;
  }
);

export const listPin = createAsyncThunk(
  "property/listPin",
  async data => {
    const res = await propertyAPI.listPin(data);
    return res;
  }
);
export const duplicatePin = createAsyncThunk(
  "property/duplicatePin",
  async data => {
    const res = await propertyAPI.duplicatePin(data);
    return res;
  }
);
