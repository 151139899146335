import PropTypes from "prop-types";
import store from "store";

export const PropType = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  allDay: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  event_type: PropTypes.string,
});

const parseDate = (d, t) => {
  let [month, day, year] = d.split("-");
  let [time, modifier] = t.split(" ");
  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }
  return new Date(year, month - 1, day, hours, minutes);
};

class Event {
  constructor(event) {
    this.id = event.id;
    this.title = event.title;
    this.start = parseDate(
      event.event_date,
      event.event_start_time
    );
    this.end = parseDate(
      event.event_date,
      event.event_end_time
    );
    this.user = event.user;
    this.job = event.job;
    this.equipments = event.equipments;
    this.calendar = event.calendar;
    this.event_types_id = event.event_types_id;
    this.date_created = event.date_created;
    this.allDay = [4].some(id => id == this.event_types_id);
  }
  getState() {
    const { states } = store.getState().system;
    const state = states.find(s => s.id === this.states_id);
    return state ? state.name : "";
  }
}

export default Event;
