/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import styles from "./WeekDatePicker.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as NextArrow } from "assets/icons/next-arrow.svg";
import { ReactComponent as PrevArrow } from "assets/icons/back-arrow.svg";
import { ReactComponent as ChevronDown } from "assets/icons/chevron-down.svg";

import { forwardRef, useEffect, useState } from "react";
import {
  format,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  subDays,
} from "date-fns";

export const CustomDatePicker = forwardRef((props, ref) => {
  const { date, onClick, value } = props;
  //new Date(`${value}`)

  useEffect(() => {}, [value]);

  return (
    <div
      className="aside-cal__month"
      onClick={onClick}
      ref={ref}
    >
      <h2 className="sm">
        {format(new Date(date), "MMMM yyyy")}
      </h2>
      <span className="chevron-down">
        <ChevronDown />
      </span>
    </div>
  );
});

CustomDatePicker.displayName = "CustomDatePicker";

const WeekDatePicker = props => {
  const { variant, className, date } = props;
  const [currentMonth, setCurrentMonth] = useState(
    new Date()
  );
  const [currentWeek, setCurrentWeek] = useState(
    getWeek(currentMonth)
  );
  const [selectedDate, setSelectedDate] = useState(
    new Date()
  );

  const [startDate, setStartDate] = useState(new Date());

  const changeWeekHandle = btnType => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
    if (btnType === "today") {
      setCurrentMonth(new Date());
      onDateClickHandle(new Date());
    }
  };

  const onDateClickHandle = day => {
    setSelectedDate(day);
    date(day);
  };

  // const renderHeader = () => {
  //   const dateFormat = "MMM yyyy";
  //   return (
  //     <div>
  //       {<span>{format(currentMonth, dateFormat)}</span>}
  //     </div>
  //   );
  // };

  const renderDays = () => {
    const dateFormat = "EEEEE";
    const days = [];
    let startDate = startOfWeek(currentMonth, {
      weekStartsOn: 1,
    });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col day" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return (
      <div className="row aside-cal__days">{days}</div>
    );
  };

  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, {
      weekStartsOn: 1,
    });
    const endDate = lastDayOfWeek(currentMonth, {
      weekStartsOn: 1,
    });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`col date ${
              isSameDay(day, new Date())
                ? "date--current"
                : isSameDay(day, selectedDate)
                ? "date--selected"
                : ""
            }`}
            key={day}
            onClick={() => {
              const dayStr = format(
                cloneDay,
                "ccc dd MMM yy"
              );
              onDateClickHandle(cloneDay, dayStr);
            }}
          >
            <span className="bg">{formattedDate}</span>
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row aside-cal__dates" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  const renderFooter = () => {
    return (
      <div className="aside-cal__nav">
        <div className="prev nav-arrow">
          <div
            className="icon"
            onClick={() => changeWeekHandle("prev")}
          >
            <PrevArrow />
          </div>
        </div>

        <div
          className="next nav-arrow"
          onClick={() => changeWeekHandle("next")}
        >
          <div className="icon">
            <NextArrow />
          </div>
        </div>
        <div
          className="nav-btn"
          title="Go to Today"
          onClick={() => changeWeekHandle("today")}
        >
          Today
        </div>
      </div>
    );
  };

  const handleDatePick = d => {
    setCurrentMonth(d);
    onDateClickHandle(d);
  };

  return (
    <div
      className={`${styles.WeekDatePicker} ${
        styles[`WeekDatePicker__${variant}`]
      } ${className} aside-cal`}
    >
      <div className="aside-cal__header">
        <div className="date-picker">
          <DatePicker
            selected={currentMonth}
            onChange={date => setStartDate(date)}
            onSelect={date => handleDatePick(date)}
            customInput={
              <CustomDatePicker date={currentMonth} />
            }
          />
        </div>
        <div>{renderFooter()}</div>
      </div>

      {renderDays()}
      {renderCells()}
    </div>
  );
};

WeekDatePicker.defaultProps = {
  variant: "default",
  className: "",
};

WeekDatePicker.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  date: PropTypes.func,
};

CustomDatePicker.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
  date: PropTypes.object,
};

export default WeekDatePicker;
