import React, {
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import Loader from "components/layouts/Loader";
import PreNavbar from "components/layouts/PreNavbar";
import AdminNav from "components/layouts/AdminNav";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as _systemActions from "store/system/actions";
import * as _userActions from "store/user/actions";
import { userActions as actions } from "store/user";
import { useDispatch, useSelector } from "react-redux";
import {
  authUser,
  notifications,
} from "store/user/selectors";
import socket from "utils/pusher";
import { toSerializable } from "helpers";

const MainLayout = props => {
  const notificationSubscribed = useRef(false);

  const [loading, setLoading] = useState(true);

  const user = useSelector(authUser);

  const _notifications = useSelector(notifications);

  const dispatch = useDispatch();

  const systemActions = useMemo(
    () => bindActionCreators(_systemActions, dispatch),
    [dispatch]
  );

  const userActions = useMemo(
    () => bindActionCreators(_userActions, dispatch),
    [dispatch]
  );

  const init = async () => {
    setLoading(true);

    const initSequence = [systemActions.getConfig()];

    if (!user?.id) {
      initSequence.push(userActions.profile());
    }

    initSequence.push(userActions.notificationsCount());

    await Promise.all(initSequence);

    setLoading(false);
  };

  useEffect(() => {
    if (notificationSubscribed.current) {
      return;
    }
    if (!user.id) return;
    var channel = socket.subscribe(
      `private-App.Models.User.${user.hash}`
    );
    channel.bind(
      "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
      _notification => {
        const { id, date_created, ...rest } = _notification;

        const isExisting = _notifications.data.some(
          n => n.id == id
        );

        const notification = {
          id,
          date_created,
          read_at: null,
          data: {
            user: toSerializable(user),
            ...rest,
          },
        };
        if (isExisting) {
          dispatch(
            actions.updateNotification(notification)
          );
        } else {
          dispatch(
            actions.createNotification(notification)
          );
        }
      }
    );
    notificationSubscribed.current = true;
  }, [user]);

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <header>
        <PreNavbar />
        <AdminNav />
      </header>
      <main>
        <Suspense fallback={<Loader />}>
          {loading ? <Loader /> : props.children}
        </Suspense>
      </main>
      <footer></footer>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
