/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import {
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";

import styles from "./Notifications.module.scss";

import * as _userActions from "store/user/actions";

import { userActions as actions } from "store/user";

import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import format from "date-fns/format";
import SectionLoader from "components/utils/SectionLoader";

import {
  authUser,
  notifications,
} from "store/user/selectors";

import { ReactComponent as JobCreated } from "assets/icons/notifications/job-created.svg";
import { ReactComponent as ClientCreated } from "assets/icons/notifications/client-created.svg";
import { ReactComponent as ClientUpdated } from "assets/icons/notifications/client-updated.svg";
import { ReactComponent as CrewAssigned } from "assets/icons/notifications/crew-assigned.svg";
import { ReactComponent as EstimateStatusUpdated } from "assets/icons/notifications/estimate-status-updated.svg";
import { ReactComponent as EventCreated } from "assets/icons/notifications/event-created.svg";
import { ReactComponent as EventUpdated } from "assets/icons/notifications/event-updated.svg";
import { ReactComponent as EstimateApproved } from "assets/icons/notifications/estimate-approved.svg";
import { ReactComponent as EstimateApproval } from "assets/icons/notifications/estimate-approval.svg";
import { ReactComponent as EstimatePriorReminder } from "assets/icons/notifications/estimate-prior-reminder.svg";
import { ReactComponent as EstimateReminder } from "assets/icons/notifications/estimate-reminder.svg";
import { ReactComponent as JobScheduled } from "assets/icons/notifications/job-scheduled.svg";
import { ReactComponent as JobPriorReminder } from "assets/icons/notifications/job-prior-reminder.svg";
import { ReactComponent as JobReminder } from "assets/icons/notifications/job-reminder.svg";
import { ReactComponent as JobStatusUpdated } from "assets/icons/notifications/job-status-updated.svg";
import { ReactComponent as JobUpdated } from "assets/icons/notifications/job_updated.svg";
import { ReactComponent as PropertyCreated } from "assets/icons/notifications/property_created.svg";
import { ReactComponent as PropertyUpdated } from "assets/icons/notifications/property_updated.svg";
import { ReactComponent as PinCreated } from "assets/icons/notifications/pin_created.svg";
import { ReactComponent as PinUpdated } from "assets/icons/notifications/pin_updated.svg";
import { ReactComponent as TaskCreated } from "assets/icons/notifications/task_created.svg";
import { ReactComponent as TaskUpdated } from "assets/icons/notifications/task_updated.svg";
import { ReactComponent as JobCommentCreated } from "assets/icons/notifications/job_comment_created.svg";
import { ReactComponent as JobCommentUpdated } from "assets/icons/notifications/job_comment_updated.svg";
import { ReactComponent as CrewUpdated } from "assets/icons/notifications/crew_updated.svg";

import { useNavigate } from "react-router-dom";

import InfiniteScroll from "react-infinite-scroll-component";

const Notification = props => {
  const { item, onMarkAsRead } = props;

  const navigate = useNavigate();

  const handleTitleClick = item => {
    const { job_id, pin_id, property_id } = item.data;

    if (job_id && property_id && pin_id) {
      navigate(
        `/job/${job_id}/property/${property_id}/pin/${pin_id}`
      );
    } else if (job_id && property_id) {
      navigate(`/job/${job_id}/property/${property_id}`);
    } else if (job_id) {
      navigate(`/job/${item.data.job_id}`);
    } else if (!job_id && property_id && pin_id) {
      navigate(`/property/${property_id}/pin/${pin_id}`);
    } else if (!job_id && property_id) {
      navigate(`/property/${property_id}`);
    } else {
      navigate(`/`);
    }
  };

  const renderIcon = type => {
    if (type === 1) {
      return <JobCreated />;
    } else if (type === 2) {
      return <ClientCreated />;
    } else if (type === 3) {
      return <ClientUpdated />;
    } else if (type === 4) {
      return <CrewAssigned />;
    } else if (type === 5) {
      return <EstimateStatusUpdated />;
    } else if (type === 6 || type === 26) {
      return <EventCreated />;
    } else if (type === 7) {
      return <EventUpdated />;
    } else if (type === 8) {
      return <EstimateApproved />;
    } else if (type === 9) {
      return <EstimateApproval />;
    } else if (type === 10) {
      return <EstimatePriorReminder />;
    } else if (type === 11) {
      return <EstimateReminder />;
    } else if (type === 12) {
      return <JobScheduled />;
    } else if (type === 13) {
      return <JobPriorReminder />;
    } else if (type === 14) {
      return <JobReminder />;
    } else if (type === 15) {
      return <JobStatusUpdated />;
    } else if (type === 16) {
      return <JobUpdated />;
    } else if (type === 17) {
      return <PropertyCreated />;
    } else if (type === 18) {
      return <PropertyUpdated />;
    } else if (type === 19) {
      return <PinCreated />;
    } else if (type === 20) {
      return <PinUpdated />;
    } else if (type === 21) {
      return <TaskCreated />;
    } else if (type === 22) {
      return <TaskUpdated />;
    } else if (type === 23) {
      return <JobCommentCreated />;
    } else if (type === 24) {
      return <JobCommentUpdated />;
    } else if (type === 25) {
      return <CrewUpdated />;
    }
  };

  const renderNameOrAddress = (fName, lName, address) => {
    if (address) {
      return `${address}`;
    } else {
      return `${fName} ${lName}`;
    }
  };

  const isClickable = item => {
    return Object.keys(item.data).some(
      key =>
        item.data[key] &&
        ["job_id", "pin_id", "property_id"].includes(key)
    );
  };

  return (
    <div className="notification__group">
      <div className="notification__thumb">
        {renderIcon(item?.data?.notification_type_id)}
      </div>
      <div className="notification__details">
        <div
          className={`notification__title ${
            isClickable(item) ? "clickable" : ""
          }`}
        >
          <h3 onClick={() => handleTitleClick(item)}>
            {item.data?.title}
          </h3>
        </div>
        {
          <div
            className={`notification__title ${
              isClickable(item) ? "clickable" : ""
            }`}
          >
            <h3 onClick={() => handleTitleClick(item)}>
              {item.data?.body}
            </h3>
          </div>
        }
        <p>
          {renderNameOrAddress(
            item?.data?.user.first_name,
            item?.data?.user?.last_name,
            item?.data?.address
          )}
        </p>
        <span>
          {format(
            new Date(new Date(item?.date_created)),
            "EEE, MMM do, yyyy 'at' h:mm a"
          )}
          {/* {item?.date_created} */}
        </span>
      </div>
      <div className="notification__status">
        <button
          className={`checkmark ${
            !item.read_at && "marked-as-read"
          }`}
          onClick={() => {
            onMarkAsRead(item);
          }}
        >
          <span></span>
        </button>
        <div className="notification__tooltip">
          {item.read_at ? (
            <p>Mark as unread</p>
          ) : (
            <p>Mark as read</p>
          )}
        </div>
      </div>
    </div>
  );
};

//###############################
//######## MAIN COMPONENT ############
//###############################
const Notifications = props => {
  const initialized = useRef(false);

  const _user = useSelector(authUser);

  const _notifications = useSelector(notifications);

  const dispatch = useDispatch();

  const userActions = useMemo(
    () => bindActionCreators(_userActions, dispatch),
    [dispatch]
  );

  const { loading, records, page } = _notifications;

  const allNotifications = _notifications.data || [];

  const [loader, setLoader] = useState(false);

  const handleMarkAllAsRead = async () => {
    await userActions.markAllRead();
  };

  const fetchNotifications = async () => {
    setLoader(true);
    const options = {
      params: {
        display: "all",
        page: initialized.current ? page + 1 : page,
      },
    };
    const res = await userActions
      .notifications({ options })
      .unwrap();
    setLoader(false);
  };

  useEffect(() => {
    if (!loading) {
      fetchNotifications();
    }
    initialized.current = true;
  }, []);

  const handleMarkAsRead = async notification => {
    const options = {
      query: {
        id: notification.id,
      },
    };
    if (notification.read_at) {
      const _notification = {
        ...notification,
        read_at: null,
      };
      dispatch(actions.updateNotification(_notification));
      userActions.markAsUnRead({ options });
    } else {
      const _notification = {
        ...notification,
        read_at: new Date().toISOString(),
      };
      dispatch(actions.updateNotification(_notification));
      userActions.markAsRead({ options });
    }
  };

  return (
    <div
      className={`${styles.Notifications} ${props.className}`}
    >
      <>
        <div className="notification__header">
          <h2 className="lg">Notifications</h2>
          {!!allNotifications.length && (
            <span
              className="notification__btn"
              onClick={handleMarkAllAsRead}
            >
              Mark all as read
            </span>
          )}
        </div>
        <div className="tray">
          {!allNotifications.length && loader ? (
            <SectionLoader />
          ) : (
            <InfiniteScroll
              dataLength={allNotifications?.length}
              next={fetchNotifications}
              hasMore={allNotifications?.length < records}
              loader={<SectionLoader />}
              endMessage={
                <div className="no-more">
                  You are all caught Up!
                </div>
              }
              height={750}
            >
              {allNotifications?.map((item, index) => (
                <Notification
                  key={index}
                  item={item}
                  index={index}
                  notifications={allNotifications}
                  onMarkAsRead={handleMarkAsRead}
                />
              ))}
            </InfiniteScroll>
          )}
        </div>
      </>
    </div>
  );
};

Notifications.defaultProps = {
  className: "",
};

Notifications.propTypes = {
  className: PropTypes.string,
  count: PropTypes.func,
  initialNotifications: PropTypes.array,
  loading: PropTypes.bool,
  getNotifications: PropTypes.func,
  records: PropTypes.number,
};

Notification.propTypes = {
  item: PropTypes.object,
  filteredNotifications: PropTypes.func,
  notifications: PropTypes.array,
  onMarkAsRead: PropTypes.func,
};

export default Notifications;
