import api from "utils/api";

export const list = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/jobs",
    method: "GET",
    data,
    ...options,
  });
};

export const create = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/job",
    method: "POST",
    data,
    ...options,
  });
};

export const update = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/job/:id",
    method: "PATCH",
    data,
    ...options,
  });
};

export const get = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/job/:id",
    method: "GET",
    data,
    ...options,
  });
};

export const remove = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/job/:id",
    method: "DELETE",
    data,
    ...options,
  });
};

export const addTask = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/task",
    method: "POST",
    data,
    ...options,
  });
};

export const getTask = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/task/:id",
    method: "GET",
    data,
    ...options,
  });
};

export const editTask = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/task/:id",
    method: "PATCH",
    data,
    ...options,
  });
};

export const deleteTask = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/task/:id",
    method: "DELETE",
    data,
    ...options,
  });
};

export const addEstimate = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/job/:id/estimate",
    method: "POST",
    data,
    ...options,
  });
};

export const sentToClient = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/job-estimate/:id/send-to-client",
    method: "POST",
    data,
    ...options,
  });
};

export const addHourUpdate = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/job/:id/hour-update",
    method: "POST",
    data,
    ...options,
  });
};

export const editHourUpdate = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/job/:id/hour-update",
    method: "POST",
    data,
    ...options,
  });
};

export const completeJob = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/job/:id/complete",
    method: "POST",
    data,
    ...options,
  });
};

export const getEstimate = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/job-estimate/:id",
    method: "GET",
    data,
    ...options,
  });
};

export const returnStatusUpdate = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/job/:id/return-status-update",
    method: "POST",
    data,
    ...options,
  });
};

export const showEstimate = (config = {}) => {
  const { options } = config;
  return api({
    url: "/v1/estimate/:id",
    method: "GET",
    ...options,
  });
};

export const clientEstimate = (config = {}) => {
  const { options, data } = config;
  return api({
    url: "/v1/estimate/:id/estimate-review",
    method: "POST",
    data,
    ...options,
  });
};

export const printEstimate = (config = {}) => {
  const { options } = config;
  return api({
    url: "/v1/job/:id/print-estimate",
    method: "GET",
    ...options,
  });
};

/**
 * comments
 */
export const listComments = (config = {}) => {
  const { options, data } = config;
  return api({
    url: "/v1/job/:id/comments",
    method: "GET",
    data,
    ...options,
  });
};

export const createComment = (config = {}) => {
  const { options, data } = config;
  return api({
    url: "/v1/job-comments",
    method: "POST",
    data,
    ...options,
  });
};

export const deleteComment = (config = {}) => {
  const { options, data } = config;
  return api({
    url: "/v1/job-comments/:id",
    method: "DELETE",
    data,
    ...options,
  });
};

export const updateComment = (config = {}) => {
  const { options, data } = config;
  return api({
    url: "/v1/job-comments/:id",
    method: "PATCH",
    data,
    ...options,
  });
};

export const updateEstimate = (config = {}) => {
  const { options, data } = config;
  return api({
    url: "/v1/job/:id/estimate/:estimate_id",
    method: "PATCH",
    data,
    ...options,
  });
};

export const AssignCrew = (config = {}) => {
  const { options, data } = config;
  return api({
    url: "/v1/job/:id/assign-crew",
    method: "POST",
    data,
    ...options,
  });
};

export const scheduleReturnJob = (config = {}) => {
  const { options, data } = config;
  return api({
    url: "/v1/job/:id/schedule-return-job",
    method: "POST",
    data,
    ...options,
  });
};
