import PropTypes from "prop-types";
import React from "react";
import styles from "./TextInput.module.scss";

import { sysPhone } from "helpers";

const TextInput = React.forwardRef((props, ref) => {
  const {
    name,
    type,
    label,
    placeholder,
    value,
    errors,
    onChange,
    onBlur,
    variant,
  } = props;

  const handleChange = e => {
    switch (type) {
      case "tel":
        !!onChange &&
          onChange({
            target: {
              name,
              value: sysPhone(e.target.value),
            },
          });
        break;
      default:
        !!onChange && onChange(e);
    }
  };

  return (
    <div
      className={`${styles.TextInput} ${
        styles[`TextInput__${variant}`]
      } ${props.className}`}
    >
      {label ? (
        <label htmlFor={name} className="form-label">
          {label}
        </label>
      ) : null}
      <div className="input-wrap">
        <input
          className="form-control"
          id={name}
          name={name}
          type={type}
          value={value}
          ref={ref}
          onChange={handleChange}
          onBlur={onBlur}
          maxLength="50"
          placeholder={
            variant === "standard" ? placeholder : undefined
          }
        />
        <label className="placeholder">{placeholder}</label>
      </div>
      {errors && errors.length > 0 && (
        <div className="form-errors">
          {errors.map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )}
    </div>
  );
});

TextInput.displayName = "TextInput";

TextInput.defaultProps = {
  className: "",
  type: "text",
  errors: [],
  onChange: () => {},
  variant: "default",
};

TextInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  variant: PropTypes.string,
};

export default TextInput;
