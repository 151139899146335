import { ReactComponent as Travel } from "assets/icons/directions-car-black.svg";
import { ReactComponent as Groups } from "assets/icons/groups-black.svg";
import { ReactComponent as Block } from "assets/icons/block-black.svg";
import { ReactComponent as TimeOff } from "assets/icons/work-off-black.svg";
import { ReactComponent as CrewTime } from "assets/icons/work-black.svg";
import { ReactComponent as Equipment } from "assets/icons/construction-black.svg";
import { ReactComponent as Job } from "assets/icons/job.svg";
import { ReactComponent as Estimate } from "assets/icons/estimate.svg";
import { ReactComponent as Tree } from "assets/icons/tree.svg";

//
const icons = {
  Travel: <Travel />,
  Meeting: <Groups />,
  Block: <Block />,
  Time_off: <TimeOff />,
  Crew_time: <CrewTime />,
  Equipment: <Equipment />,
  Job: <Job />,
  Estimate: <Estimate />,
  Tree: <Tree />,
};

export default icons;
