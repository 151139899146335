import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./actions";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    trends: {},
    jobs: {},
    sales: {},
    loading: false,
  },
  extraReducers: {
    [actions.getEvents.pending]: state => {
      state.loading = true;
    },
    [actions.getEvents.fulfilled]: (state, action) => {
      state.events = action.payload.data || [];
      state.loading = false;
    },
    [actions.getEvents.rejected]: state => {
      state.loading = false;
    },
    //
    [actions.getActiveJobs.pending]: state => {
      state.loading = true;
    },
    [actions.getActiveJobs.fulfilled]: (state, action) => {
      const _jobs = action.payload.data;
      state.jobs = _jobs;
      state.loading = false;
    },
    [actions.getActiveJobs.rejected]: state => {
      state.loading = false;
    },
    //
    [actions.getTrendsData.pending]: state => {
      state.loading = true;
    },
    [actions.getTrendsData.fulfilled]: (state, action) => {
      state.trends = action.payload.data || {};
      state.loading = false;
    },
    [actions.getTrendsData.rejected]: state => {
      state.loading = false;
    },
    //
    [actions.getSales.pending]: state => {
      state.loading = true;
    },
    [actions.getSales.fulfilled]: (state, action) => {
      state.sales = action.payload.data || {};
      state.loading = false;
    },
    [actions.getSales.rejected]: state => {
      state.loading = false;
    },
  },
});

export const dashboardActions = dashboardSlice.actions;
export default dashboardSlice.reducer;
