/* eslint-disable no-unused-vars */
import SelectInput, {
  Option,
} from "components/utils/SelectInput";
import WeekDatePicker from "components/utils/WeekDatePicker";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import styles from "./DashboardCalendar.module.scss";
// import startOfWeek from "date-fns/startOfWeek";
// import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import {
  crews,
  estimators,
} from "store/calendar/selectors";
import { authUser } from "store/user/selectors";
import { useSelector, useDispatch } from "react-redux";
import * as _calendarActions from "store/calendar/actions";
import { bindActionCreators } from "redux";
import format from "date-fns/format";
import icons from "helpers/icons";
import isEqual from "date-fns/isEqual";
import isTomorrow from "date-fns/isTomorrow";
import { ReactComponent as Estimate } from "assets/icons/estimate.svg";
import { ReactComponent as Job } from "assets/icons/job.svg";
import { useNavigate } from "react-router-dom";
import SectionLoader from "components/utils/SectionLoader";
import { isToday } from "date-fns";

const RenderDateAndEvents = props => {
  const { date, events } = props;

  const navigate = useNavigate();

  // 3 Jun • Today • Friday
  //{format(date, "d MMM • EEEE")}

  const chooseEventIcon = item => {
    if (item.event_types_id === 1) {
      return icons.Travel;
    } else if (item.event_types_id === 2) {
      return icons.Meeting;
    } else if (item.event_types_id === 3) {
      return icons.Block;
    } else if (item.event_types_id === 4) {
      return icons.Time_off;
    } else if (item.event_types_id === 5) {
      return icons.Crew_time;
    } else if (item.event_types_id === 6) {
      return icons.Equipment;
    } else if (item.event_types_id > 6 && !item.job) {
      return <Estimate />;
    }
  };

  const chooseJobIcon = status => {
    if (status < 4) {
      return <Estimate />;
    } else {
      return <Job />;
    }
  };

  const renderDateLabel = d => {
    const date = d.replace(/(..).(..).(....)/, "$3-$1-$2");
    if (isToday(new Date(date))) {
      return format(
        new Date(date),
        "d MMM • 'Today' • EEEE"
      );
    } else if (
      isTomorrow(
        new Date(date),
        new Date(new Date().toDateString())
      )
    ) {
      return format(
        new Date(date),
        "d MMM • 'Tomorrow' • EEEE"
      );
    } else {
      return format(new Date(date), "d MMM • EEEE");
    }
  };

  const navigateToJobPage = evt => {
    const jobId = evt.job.id;
    navigate(`/job/${jobId}`);
  };

  useEffect(() => {}, [events.length]);

  return events.length
    ? events.map((item, index) => (
        <div key={index} className="date-group">
          <div className="date-group__header">
            <h3 className="lg">
              {renderDateLabel(item?.date)}
            </h3>
          </div>
          {/* details */}
          <div className="date-group__details">
            {/* mapping for events */}
            {item.events.map((item, index) => {
              if (item.job) {
                return (
                  <div
                    onClick={() => navigateToJobPage(item)}
                    key={index}
                    className="event-group"
                  >
                    <div className="event-group__row event-group__row--dark">
                      <div className="event-group__details">
                        <span className="event-group__thumb">
                          {chooseJobIcon(
                            item.job.job_status.value
                          )}
                        </span>
                        <h3>
                          {`${item.job.client.first_name} ${item.job.client.last_name}`}
                        </h3>
                      </div>
                      <div className="event-group__time">
                        <span>{item.event_start_time}</span>
                      </div>
                    </div>
                    <div className="event-group__row event-group__row--light">
                      <div className="event-group__details">
                        {item.job?.property_addresses.map(
                          (item, index) => (
                            <p key={index}>{item}</p>
                          )
                        )}
                      </div>
                      <div className="event-group__time">
                        <span>{item.event_end_time}</span>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={index} className="event-group">
                    <div className="event-group__row event-group__row--dark">
                      <div className="event-group__details">
                        <span className="event-group__thumb">
                          {chooseEventIcon(item)}
                        </span>
                        <h3>{item.title}</h3>
                      </div>
                      <div className="event-group__time">
                        <span>{item.event_start_time}</span>
                      </div>
                    </div>
                    <div className="event-group__row event-group__row--light">
                      <div className="event-group__details">
                        <h3></h3>
                      </div>
                      <div className="event-group__time">
                        <span>{item.event_end_time}</span>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ))
    : "No events to display";
};

const DashboardCalendar = props => {
  const { variant, className } = props;
  const dispatch = useDispatch();

  const calendarActions = useMemo(
    () => bindActionCreators(_calendarActions, dispatch),
    [dispatch]
  );

  const _authUser = useSelector(authUser);

  const _crews = useSelector(crews);
  const _estimators = useSelector(estimators);
  const groups = [..._crews, ..._estimators];

  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState();
  const [events, setEvents] = useState([]);

  //This function will group events based on event date
  let xx = [];
  const sortedEvents = all => {
    for (let i = 0; i < all.length; i++) {
      let obj = {
        date: all[i].event_date,
        events: all.filter(
          item => item.event_date === all[i].event_date
        ),
      };

      xx.push(obj);
    }

    return [
      ...new Map(
        xx.map(item => [item["date"], item])
      ).values(),
    ];
  };

  const onDateClick = _date => {
    setDate(_date);
  };

  const handleSelect = e => {
    if (e.target.value === "all") {
      setSelection("all");
      return;
    }
    setSelection(e.target.value);
  };

  const listEvents = async () => {
    setLoading(true);
    const options = {
      params: {
        start_date: date.toDateString(),
        end_date: addDays(date, 7).toDateString(),
        // end_date: endOfWeek.toDateString(),
        users: selection !== "all" ? selection : "",
        all_users: selection === "all" ? true : false,
        extend_jobs: true,
      },
    };

    const res = await calendarActions
      .list({ options })
      .unwrap();
    if (res.success) {
      setEvents(res.data);
    }
    setLoading(false);
  };

  const fetchCalendarSettings = async () => {
    const res = await calendarActions.fetchSettings();
    if (res.payload.success) {
      // console.log(res);
    }
  };

  useEffect(async () => {
    await fetchCalendarSettings();
    if (
      _authUser.role.slug !== "scheduling-admin" &&
      _authUser.role.slug !== "admin"
    ) {
      setSelection(_authUser.hash);
    } else {
      setSelection("all");
    }
  }, []);

  useEffect(async () => {
    await listEvents();
  }, [date, selection]);

  return (
    <div
      className={`${styles.DashboardCalendar} ${
        styles[`DashboardCalendar__${variant}`]
      } ${className}`}
    >
      <div className="dboard-cal">
        <div className="dboard-cal__header">
          <h2 className="lg">Calendar</h2>
          <SelectInput
            name="groups"
            variant="standard"
            onChange={handleSelect}
            value={selection}
          >
            {/* <Option value={_authUser.hash}>
              {_authUser.calendar_name}
            </Option> */}
            {groups.length
              ? groups.map(item => {
                  return (
                    <Option value={item.hash} key={item.id}>
                      {item.calendar_name}
                    </Option>
                  );
                })
              : ""}
            <Option value="all">All</Option>
          </SelectInput>
        </div>
        <div>
          <WeekDatePicker date={onDateClick} />
        </div>

        <div className="dboard-cal__content">
          {loading ? (
            <SectionLoader />
          ) : (
            <RenderDateAndEvents
              date={date}
              events={sortedEvents(events)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

DashboardCalendar.defaultProps = {
  variant: "default",
  className: "",
};

DashboardCalendar.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

RenderDateAndEvents.propTypes = {
  date: PropTypes.object,
  events: PropTypes.array,
};

export default DashboardCalendar;
