import React, { Fragment } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import history from "./history";
import AuthLayout from "layouts/AuthLayout";
import MainLayout from "layouts/MainLayout";
import SubLayout from "layouts/SubLayout";
import PublicLayout from "layouts/PublicLayout";

/**
 * Route listed below
 */

import Dashboard from "pages/Dashboard";
import Login from "pages/Login";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const routes = [
  {
    path: "/login",
    component: Login,
    layout: AuthLayout,
    publicRoute: true,
  },
  {
    path: "/forgot-password",
    component: React.lazy(() =>
      import("pages/ForgotPassword")
    ),
    layout: AuthLayout,
    publicRoute: true,
  },
  {
    path: "/reset-password",
    component: React.lazy(() =>
      import("pages/ResetPassword")
    ),
    layout: AuthLayout,
    publicRoute: true,
  },
  {
    path: "/",
    component: Dashboard,
    layout: MainLayout,
    privateRoute: true,
  },
  {
    path: "/calendar",
    component: React.lazy(() => import("pages/Calendar")),
    layout: MainLayout,
    privateRoute: true,
  },
  {
    path: "/new-job",
    component: React.lazy(() => import("pages/NewJob")),
    layout: SubLayout,
    privateRoute: true,
  },
  {
    path: "/job",
    component: React.lazy(() => import("pages/Job")),
    layout: MainLayout,
    privateRoute: true,
    routes: [
      {
        path: "/job/:job_id",
        component: React.lazy(() =>
          import("components/jobs/JobDetails")
        ),
      },
      {
        path: "/job/:job_id/property/:property_id",
        component: React.lazy(() =>
          import("components/jobs/JobProperty")
        ),
      },
      {
        path: "/job/:job_id/property/:property_id/pin/:pin_id",
        component: React.lazy(() =>
          import("components/jobs/JobPin")
        ),
      },
      {
        path: "/job/:job_id/estimation",
        component: React.lazy(() =>
          import("components/jobs/JobEstimation")
        ),
      },
      // {
      //   path: "/job/:job_id/send-estimate",
      //   component: React.lazy(() =>
      //     import("components/jobs/JobEstimate")
      //   ),
      // },
      {
        path: "/job/:job_id/property/:property_id/new-pin",
        component: React.lazy(() =>
          import("components/jobs/JobNewPin")
        ),
      },
      {
        path: "/job/:job_id/property/:property_id/pin/:pin_id/edit",
        component: React.lazy(() =>
          import("components/jobs/JobEditPin")
        ),
      },
    ],
  },
  {
    path: "/estimate/:estimate_id",
    component: React.lazy(() =>
      import("pages/ApproveEstimate")
    ),
    layout: PublicLayout,
    privateRoute: false,
  },
  {
    path: "/clients",
    component: React.lazy(() => import("pages/Clients")),
    layout: MainLayout,
  },
  {
    path: "/client/:client_id",
    component: React.lazy(() => import("pages/Client")),
    layout: MainLayout,
    privateRoute: true,
    routes: [
      {
        index: true,
        path: "/client/:client_id/notes",
        component: React.lazy(() =>
          import("components/clients/ClientNotes")
        ),
      },
      {
        path: "/client/:client_id/properties",
        component: React.lazy(() =>
          import("components/clients/ClientProperties")
        ),
      },
      {
        path: "/client/:client_id/jobs",
        component: React.lazy(() =>
          import("components/clients/ClientJobs")
        ),
      },
    ],
  },
  {
    path: "/properties",
    component: React.lazy(() => import("pages/Properties")),
    layout: MainLayout,
    privateRoute: true,
  },
  {
    path: "/property/:property_id",
    component: React.lazy(() => import("pages/Property")),
    layout: MainLayout,
    privateRoute: true,
    routes: [
      {
        index: true,
        path: "/property/:property_id/pins",
        component: React.lazy(() =>
          import("components/properties/PropertyPins")
        ),
      },
      {
        path: "/property/:property_id/pin",
        component: React.lazy(() =>
          import("components/properties/NewPin")
        ),
      },
      {
        path: "/property/:property_id/pin/:pin_id",
        component: React.lazy(() =>
          import("components/properties/PropertyPin")
        ),
      },
      {
        path: "/property/:property_id/pin/:pin_id/edit",
        component: React.lazy(() =>
          import("components/properties/EditPin")
        ),
      },
      {
        path: "/property/:property_id/jobs",
        component: React.lazy(() =>
          import("components/properties/PropertyJobs")
        ),
      },
    ],
  },
  {
    path: "/users",
    component: React.lazy(() => import("pages/Users")),
    layout: MainLayout,
    privateRoute: true,
  },
  {
    path: "/map",
    component: React.lazy(() => import("pages/Map")),
    layout: MainLayout,
    privateRoute: true,
  },
  {
    path: "/maintenance",
    component: React.lazy(() =>
      import("pages/Maintenance")
    ),
    layout: MainLayout,
    privateRoute: false,
  },
  {
    path: "*",
    component: React.lazy(() => import("pages/NotFound")),
    layout: MainLayout,
    privateRoute: false,
  },
];

const Element = ({
  component,
  layout,
  privateRoute,
  publicRoute,
}) => {
  const auth = useSelector(state => state.auth);

  if (privateRoute) {
    if (!auth.isAuthenticated) {
      return <Navigate to="/login" />;
    }
  } else if (publicRoute) {
    if (auth.isAuthenticated) {
      return <Navigate to="/" />;
    }
  }

  const Component = component;
  const Layout = layout || Fragment;

  return (
    <Layout>
      <Component />
    </Layout>
  );
};

Element.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.func,
  privateRoute: PropTypes.bool,
  publicRoute: PropTypes.bool,
};

const createRoutes = routes => {
  return routes.map(route => {
    if (route.routes?.length) {
      const indexRoute = route.routes.find(r => r.index);
      return (
        <Route
          key={route.path}
          path={route.path}
          element={<Element {...route} />}
          index={route.index}
        >
          {!!indexRoute && (
            <Route
              index
              element={<Element {...indexRoute} />}
            />
          )}
          {createRoutes(route.routes)}
        </Route>
      );
    }
    return (
      <Route
        key={route.path}
        path={route.path}
        element={<Element {...route} />}
        index={route.index}
      />
    );
  });
};

export default function AppRouter() {
  return (
    <Router location={history.location} navigator={history}>
      <Routes>{createRoutes(routes)}</Routes>
    </Router>
  );
}
