import React, { Suspense } from "react";
import PropTypes from "prop-types";
import Loader from "components/layouts/Loader";
import BrandNav from "components/layouts/BrandNav";

const PublicLayout = props => {
  return (
    <>
      <header>
        <BrandNav />
      </header>
      <main>
        <Suspense fallback={<Loader />}>
          {props.children}
        </Suspense>
      </main>
      <footer></footer>
    </>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.node,
};

export default PublicLayout;
