import SearchInput from "components/utils/SearchInput";
import SectionLoader from "components/utils/SectionLoader";
import PropTypes from "prop-types";
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styles from "./Search.module.scss";
import * as _jobActions from "store/job/actions";
import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Client from "models/Client";
import { usePopper } from "react-popper";
import debounce from "utils/debounce";
import OutsideClickHandler from "components/utils/OutsideClickHandler";

const Search = props => {
  const dispatch = useDispatch();

  const jobActions = useMemo(
    () => bindActionCreators(_jobActions, dispatch),
    [dispatch]
  );

  const [referenceElement, setReferenceElement] =
    useState(null);

  const [popperElement, setPopperElement] = useState(null);

  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [
        {
          name: "offset",
          enabled: true,
          options: {
            offset: [0, 0],
          },
        },
      ],
    }
  );

  const [loading, setLoading] = useState(true);

  const [keyword, setKeyword] = useState("");

  const [results, setResults] = useState([]);

  const getHighlightedText = (text, highlight) => {
    const parts = text.split(
      new RegExp(`(${highlight})`, "gi")
    );
    return (
      <span>
        {parts.map(part =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <b>{part}</b>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const jobSelected = () => {
    resetValue();
  };

  const renderResults = result => {
    const client = new Client(result.client);
    return (
      <li onClick={jobSelected}>
        <Link to={`/job/${result.id}`}>
          <div>
            <h3 className="client-name">
              {getHighlightedText(
                client.fullName(),
                keyword
              )}
            </h3>
            <div className="address">
              {getHighlightedText(
                client.address.name,
                keyword
              )}
            </div>
            <div className="email">
              {getHighlightedText(client.email, keyword)}
            </div>
          </div>
        </Link>
      </li>
    );
  };

  const fetchJobs = async options => {
    setResults([]);
    const res = await jobActions.list({ options }).unwrap();
    if (res.success) {
      setResults(res.data);
    }
    setLoading(false);
  };

  const debounceFetchJobs = useCallback(
    debounce(fetchJobs),
    []
  );

  const handleSearch = e => {
    setKeyword(e.target.value);
  };

  const resetValue = () => {
    setKeyword("");
  };

  const handleOutSideClick = () => {
    setKeyword("");
  };

  useEffect(() => {
    setLoading(true);
    const options = {
      params: {
        keyword: keyword || undefined,
      },
    };
    debounceFetchJobs(options);
  }, [keyword]);

  return (
    <div className={`${styles.Search} ${props.className}`}>
      <div className="search-bar" ref={setReferenceElement}>
        <SearchInput
          placeholder="Search"
          value={keyword}
          onChange={handleSearch}
          resetValue={resetValue}
        />
      </div>
      <OutsideClickHandler
        onOutsideClick={handleOutSideClick}
      >
        {!!keyword.length && (
          <div
            className="results"
            ref={setPopperElement}
            style={popperStyles.popper}
            {...attributes.popper}
          >
            {loading ? (
              <SectionLoader />
            ) : (
              <>
                {results.length > 0 ? (
                  <ul className="results__list">
                    {results.map(result =>
                      renderResults(result)
                    )}
                  </ul>
                ) : (
                  <div className="no-results">
                    No results found
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

Search.defaultProps = {
  className: "",
};

Search.propTypes = {
  className: PropTypes.string,
};

export default Search;
