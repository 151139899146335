import PropTypes from "prop-types";
import styles from "./AdminNav.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import * as authActions from "store/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { authUser } from "store/user/selectors";
import Popper from "components/utils/Popper";
import { ReactComponent as DownArrow } from "assets/icons/chevron-down.svg";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const AdminNav = props => {
  const dispatch = useDispatch();

  const actions = bindActionCreators(authActions, dispatch);

  const navigate = useNavigate();

  const location = useLocation();

  const user = useSelector(state => authUser(state));

  const [userTray, setUserTray] = useState(false);

  const [clientsActive, setClientsActive] = useState(false);
  const [propsActive, setPropsActive] = useState(false);

  const handleLogout = async () => {
    const res = await actions.logout().unwrap();
    setUserTray(false);
    if (res.success) {
      navigate("/login");
    }
  };

  const addActiveClass = () => {
    if (location.pathname.includes("/client")) {
      setPropsActive(false);
      setClientsActive(true);
    } else if (
      location.pathname.includes("/property") &&
      !location.pathname.includes("/job")
    ) {
      setClientsActive(false);
      setPropsActive(true);
    } else {
      setClientsActive(false);
      setPropsActive(false);
    }
  };

  useEffect(() => {
    addActiveClass();
  }, [location.pathname]);

  return (
    <nav
      className={`${styles.AdminNav} ${props.className}`}
    >
      <div className="nav">
        <ul className="nav__list">
          <li className="nav__item">
            <NavLink
              className="nav__link"
              aria-current="page"
              to="/"
            >
              Dashboard
            </NavLink>
          </li>

          <li className="nav__item">
            <NavLink className="nav__link" to="/calendar">
              Calendar
            </NavLink>
          </li>

          <li className="nav__item">
            <NavLink className="nav__link" to="/job">
              Jobs
            </NavLink>
          </li>

          <li className="nav__item">
            <NavLink
              className={`nav__link ${
                clientsActive ? "active" : ""
              }`}
              to="/clients"
            >
              Clients
            </NavLink>
          </li>

          <li className="nav__item">
            <NavLink
              className={`nav__link ${
                propsActive ? "active" : ""
              }`}
              to="/properties"
            >
              Properties
            </NavLink>
          </li>

          {!!user.hasPermission("manage-users") && (
            <li className="nav__item">
              <NavLink className="nav__link" to="/users">
                Manage Users
              </NavLink>
            </li>
          )}

          <li className="nav__item ms-auto">
            <Popper
              onOpen={() => setUserTray(true)}
              onClose={() => setUserTray(false)}
              open={userTray}
              className="nav__user-tray user-tray"
              offset={[-58, 0]}
              // offset={[-65, 10]}
              trigger={
                <div className="nav__user">
                  <a className="nav__link">
                    {user.fullName()}
                  </a>
                  <DownArrow />
                </div>
              }
            >
              <span></span>
              <div className="user-tray__content">
                <h3 title={user.fullName()}>
                  {user.fullName()}
                </h3>
                <p title={user.email}>{user.email}</p>
              </div>
              <a
                className="user-tray__cta"
                onClick={handleLogout}
              >
                Sign out
              </a>
            </Popper>
          </li>
        </ul>
      </div>
      {/* Add show or hide class to .nav-mob to show and hide the nav list menu  */}
      <div className="nav-mob hide">
        <ul className="nav-mob__list">
          <li className="nav-mob__item">
            <NavLink
              className="nav-mob__link"
              aria-current="page"
              to="/"
            >
              Dashboard
            </NavLink>
          </li>
          <li className="nav-mob__item">
            <NavLink
              className="nav-mob__link"
              to="/calendar"
            >
              Calendar
            </NavLink>
          </li>
          <li className="nav-mob__item">
            <NavLink className="nav-mob__link" to="/job">
              Jobs
            </NavLink>
          </li>
          <li className="nav-mob__item">
            <NavLink
              className="nav-mob__link"
              to="/clients"
            >
              Clients
            </NavLink>
          </li>
          <li className="nav-mob__item">
            <NavLink
              className="nav-mob__link"
              to="/properties"
            >
              Properties
            </NavLink>
          </li>
          <li className="nav-mob__item">
            <NavLink className="nav-mob__link" to="/users">
              Manage Users
            </NavLink>
          </li>
          <li className="nav-mob__item">
            <a
              href="#"
              className="nav-mob__link"
              onClick={handleLogout}
            >
              Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

AdminNav.defaultProps = {
  className: "",
};

AdminNav.propTypes = {
  className: PropTypes.string,
};

export default AdminNav;
