import PropTypes from "prop-types";
import Popper from "components/utils/Popper";
import styles from "./NavNotifications.module.scss";
import { ReactComponent as Bell } from "assets/icons/bell.svg";
import Notifications from "components/users/Notifications";
import { useState } from "react";
import { notifications } from "store/user/selectors";
import { useSelector } from "react-redux";

const NavNotifications = props => {
  const { variant, className } = props;

  const [open, setOpen] = useState(false);

  const { unreadCount } = useSelector(notifications);

  const getNotificationCount = () => {
    if (unreadCount < 1000) {
      return unreadCount;
    }
    return (
      <>
        1k<sup>+</sup>
      </>
    );
  };

  return (
    <div
      className={`${styles.NavNotifications} ${
        styles[`NavNotifications__${variant}`]
      } ${className}`}
    >
      <Popper
        className="notification__popup"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => setOpen(false)}
        placement="bottom-start"
        offset={[-10, 10]}
        trigger={
          <div className="notification__bell">
            <Bell />
            {!!unreadCount && (
              <span className="count">
                {getNotificationCount()}
              </span>
            )}
          </div>
        }
      >
        <Notifications />
      </Popper>
    </div>
  );
};

NavNotifications.defaultProps = {
  variant: "default",
  className: "",
};

NavNotifications.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  count: PropTypes.number,
  notifications: PropTypes.array,
  loading: PropTypes.bool,
  getNotifications: PropTypes.func,
  records: PropTypes.number,
};

export default NavNotifications;
