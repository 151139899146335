import { createAsyncThunk } from "@reduxjs/toolkit";
import * as authAPI from "api/auth";

export const login = createAsyncThunk(
  "auth/login",
  async (options, store) => {
    const res = await authAPI.login(options);
    if (res.success) {
      store.dispatch({
        type: "user/profile/fulfilled",
        payload: res,
      });
    }
    return res;
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async options => {
    const res = await authAPI.forgotPassword(options);
    return res;
  }
);

export const validateToken = createAsyncThunk(
  "auth/validateToken",
  async options => {
    const res = await authAPI.validateToken(options);
    return res;
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async options => {
    const res = await authAPI.resetPassword(options);
    return res;
  }
);

export const session = createAsyncThunk(
  "auth/session",
  async options => {
    const res = await authAPI.session(options);
    return res;
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async options => {
    const res = await authAPI.logout(options);
    return res;
  }
);
