import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./actions";

export const jobSlice = createSlice({
  name: "job",
  initialState: {
    all: [],
    jobs: {},
    selected: {},
    loading: false,
    task: {},
    estimate: {},
    comments: [],
  },
  reducers: {
    clearJobs(state) {
      state.all = [];
    },
  },
  extraReducers: {
    [actions.list.pending]: state => {
      state.loading = true;
    },
    [actions.list.fulfilled]: (state, action) => {
      const jobs = action.payload.data || [];
      state.all = [...state.all, ...jobs];
      state.loading = false;
    },
    [actions.list.rejected]: state => {
      state.loading = false;
    },
    [actions.get.pending]: state => {
      state.loading = true;
    },
    [actions.get.fulfilled]: (state, action) => {
      const { job } = action.payload.data || {};
      state.selected = job;
      state.loading = false;
    },
    [actions.get.rejected]: state => {
      state.loading = false;
    },
    [actions.remove.pending]: state => {
      state.loading = true;
    },
    [actions.remove.fulfilled]: (state, action) => {
      const { id } = action.payload.meta || {};
      state.all = state.all.filter(item => item.id !== id);
      state.loading = false;
    },
    [actions.remove.rejected]: state => {
      state.loading = false;
    },
    [actions.getTask.pending]: state => {
      state.loading = true;
    },
    [actions.getTask.fulfilled]: (state, action) => {
      const { task } = action.payload.data || {};
      state.task = task;
      state.loading = false;
    },
    [actions.getTask.rejected]: state => {
      state.loading = false;
    },
    [actions.getEstimate.pending]: state => {
      state.loading = true;
    },
    [actions.getEstimate.fulfilled]: (state, action) => {
      const { job_estimate } = action.payload.data || {};
      state.estimate = job_estimate;
      state.loading = false;
    },
    [actions.getEstimate.rejected]: state => {
      state.loading = false;
    },
    [actions.showEstimate.pending]: state => {
      state.loading = true;
    },
    [actions.showEstimate.fulfilled]: (state, action) => {
      const { job_estimate } = action.payload.data || {};
      state.estimate = job_estimate;
      state.loading = false;
    },
    [actions.showEstimate.rejected]: state => {
      state.loading = false;
    },
    [actions.listComments.pending]: state => {
      state.loading = true;
    },
    [actions.listComments.fulfilled]: (state, action) => {
      state.comments = action.payload.data || [];
    },
    [actions.listComments.rejected]: state => {
      state.loading = false;
    },
    [actions.returnStatusUpdate.pending]: state => {
      state.loading = true;
    },
    [actions.returnStatusUpdate.fulfilled]: (
      state,
      action
    ) => {
      const { job } = action.payload.data;
      state.all = state.all.map(_job => {
        if (_job.id === job.id) {
          _job = {
            ..._job,
            ...job,
          };
        }
        return _job;
      });
      state.loading = false;
    },
    [actions.returnStatusUpdate.rejected]: state => {
      state.loading = false;
    },
    [actions.addEstimate.pending]: state => {
      state.loading = true;
    },
    [actions.addEstimate.fulfilled]: (state, action) => {
      const { job_estimation } = action.payload.data;
      state.selected = {
        ...state.selected,
        job_estimate: job_estimation,
      };
    },
    [actions.addEstimate.rejected]: state => {
      state.loading = false;
    },
    [actions.updateEstimate.pending]: state => {
      state.loading = true;
    },
    [actions.updateEstimate.fulfilled]: (state, action) => {
      const { job_estimation } = action.payload.data;
      state.selected = {
        ...state.selected,
        job_estimate: job_estimation,
      };
    },
    [actions.updateEstimate.rejected]: state => {
      state.loading = false;
    },
  },
});

export const jobActions = jobSlice.actions;

export default jobSlice.reducer;
